import api from '@/api/api'
import config from '@/config'

export async function getUsers(data) {
  return await api.get(`${config.backend}/system/users`, data)
}

export async function getUserPermission(id) {
  return await api.get(`${config.backend}/system/users/${id}/permissions`)
}

export async function updateUserPermission(id, data) {
  return await api.put(`${config.backend}/system/users/${id}/permissions`, data)
}

export async function getUserService(id) {
  return await api.get(`${config.backend}/system/users/${id}/services`)
}

export async function updateUserService(id, data) {
  return await api.put(`${config.backend}/system/users/${id}/services`, data)
}

export async function getRole(data) {
  return await api.get(`${config.backend}/system/roles`, data)
}

export async function createUser(data) {
  return await api.post(`${config.backend}/system/users`, data)
}

export async function updateUser(id, data) {
  return await api.put(`${config.backend}/system/users/${id}`, data)
}

export async function activeDeactive(id) {
  return await api.put(`${config.backend}/system/acive-deactive/${id}`)
}

export async function updateAccount(data) {
  return await api.put(`${config.backend}/update-profile`, data)
}

export async function changePassword(data) {
  return await api.put(`${config.backend}/auth/change-password`, data)
}

export async function getSharePermission() {
  return await api.get(`${config.backend}/system/share-permissions`)
}

export async function updateMenuUser(id, data) {
  return await api.put(`${config.backend}/system/users/${id}/menus`, data)
}

export async function getMenuUser(id) {
  return await api.get(`${config.backend}/system/users/${id}/menus`)
}

export async function getMenus(param) {
  return await api.get(`${config.backend}/system/menus`, param)
}

export async function getPermissions() {
  return await api.get(`${config.backend}/system/permissions`)
}

export async function updateSettingUser(id, data) {
  return await api.put(`${config.backend}/system/users/${id}/setting-properties`, data)
}

