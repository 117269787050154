<template>
  <v-dialog v-model="dialog" persistent width="800">
    <v-form ref="formData" v-model="valid" onSubmit="return false;">
      <v-card>
        <div class="pa-3">
          <div class="d-flex align-center justify-space-between">
            <div class="text-h6">
              <span>Update menu project</span>
              <v-tooltip right>
                <template v-slot:activator="{ on }">
                  <v-icon class="ml-2" v-on="on">mdi-help-circle-outline</v-icon>
                </template>
                <div>
                  <div>View: Show/Hide Menu</div>
                  <div>Default: [Only one] Choose a menu to auto access</div>
                </div>
              </v-tooltip>
            </div>
            <v-btn icon @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
          <v-divider class="mt-2" />
        </div>
        <div class="d-flex pa-3">
          <v-data-table
            :headers="headers"
            :items="listMenu"
            :items-per-page="-1"
            class="elevation-1"
            fixed-header
            height="400"
            hide-default-footer
            style="width: 100%"
          >
            <template v-slot:[`item.index`]="{ item, index }">
              <div>{{ +index + 1 }}</div>
            </template>
            <template v-slot:[`item.viewable`]="{ item }">
              <v-checkbox v-model="item.viewable" class="mt-0" color="red" hide-details></v-checkbox>
            </template>
            <template v-slot:[`item.default`]="{ item }">
              <v-checkbox
                v-model="item.default"
                class="mt-0"
                color="red"
                hide-details
                @change="makeDefault(item)"
              ></v-checkbox>
            </template>
          </v-data-table>
        </div>
        <v-card-actions>
          <v-layout>
            <v-spacer />
            <v-btn :disabled="loading" class="mx-2" color="secondary" rounded type="reset" @click="dialog = false">
              Close
            </v-btn>
            <v-btn :loading="loading" color="primary" rounded type="submit" @click="submitData">
              Submit
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import AvatarImg from '@/assets/images/user/avatar.png'
import AvatarBgImg from '@/assets/images/user/background-avatar.png'
import { updateMenuProject } from '@/api/project-api'

const TYPE_CREATE = 'create'
const TYPE_UPDATE = 'update'
export default {
  props: {
    menus: { type: Array, default: () => [] },
  },
  data: () => ({
    TYPE_CREATE,
    TYPE_UPDATE,
    dialog: false,
    loading: false,
    valid: false,
    name: undefined,
    rules: {
      feildRequired: value => !!value || 'Feild is required',
    },
    headers: [
      {
        text: '#',
        align: 'start',
        sortable: false,
        value: 'index',
      },
      {
        text: 'Menu',
        align: 'start',
        sortable: false,
        value: 'name',
      },
      {
        text: 'View',
        align: 'start',
        sortable: false,
        value: 'viewable',
        width: '200',
      },
      {
        text: 'Default',
        align: 'start',
        sortable: false,
        value: 'default',
        width: '200',
      },
    ],
    projectId: null,
    type: TYPE_CREATE,
    form: {},
    AvatarImg,
    AvatarBgImg,
    listMenu: [],
  }),
  methods: {
    makeDefault(selectedItem) {
      if(selectedItem.default && selectedItem.viewable === false) {
        this.$store.commit("message/SHOW_WARNING", 'You can not set default for unviewable menu');
        selectedItem.default = false;
        return;
      }
      this.listMenu.forEach(item => {
        if (item.id !== selectedItem.id) {
          item.default = false
        }
      })
    },
    async openDialog(item) {
      this.listMenu = item.menus
      this.projectId = item.uuid
      this.dialog = true
    },
    async submitData() {
      try {
        const menus = []
        this.listMenu.forEach(item => {
          if (!item.viewable || item.default) {
            menus.push({ id: item.id, viewable: item.viewable, default: item.default })
          }
        })
        await updateMenuProject({ project_id: this.projectId, data: { menus: menus } })
        this.dialog = false
        this.$parent.getListProject()
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
