<template>
  <v-dialog v-model="renameDialog" persistent width="500">
    <v-card class="custom-card-bg-gradient" height="100%" width="100%">
      <DialogHeader :is-close="true" :title="'Edit'" @close="renameDialog = false" />
      <v-card-text>
        <v-form ref="formData" v-model="valid" @submit.prevent="submitData">
          <v-text-field
            v-model="currentItem.name"
            :rules="[rules.nameRequired]"
            :autofocus="!isTagClick"
            dense
            label="Name"
            outlined
            height="60px"
            placeholder="Name"
          ></v-text-field>
          <v-combobox
            label="Tags"
            multiple
            ref="tagCombobox"
            chips
            :disabled="!currentItem.is_owner"
            :autofocus="isTagClick"
            small-chips
            deletable-chips
            clearable
            height="60px"
            outlined
            hide-selected
            hide-no-data
            @keyup.enter="() => tags=[]"
            :items="tags"
            :search-input.sync="search"
            v-model="currentItem.tags"
          ></v-combobox>

          <v-layout>
            <v-spacer />
            <v-btn type="submit" color="primary" rounded>
              Submit
            </v-btn>
          </v-layout>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'
import { getTags } from '@/api/project-api'

export default {
  name: 'RenameDialog',
  components: { DialogHeader },
  data() {
    return {
      renameDialog: false,
      valid: false,
      tags: [],
      search: '',
      isTagClick: false,
      rules: {
        nameRequired: value => !!value || 'Name is required',
      },
      currentItem: {},
    }
  },
  props: {
    submitEdit: Function,
  },
  watch: {
    search(val) {
      if (val && val.length > 0) {this.getTags()}
      else this.tags = []
    },
    renameDialog(val) {
      if (!val) this.currentItem = {}
    },
  },

  methods: {
    openDialog(item, isTagClick = false) {
      this.tags = []
      this.isTagClick = isTagClick
      this.currentItem = { ...item }
      this.currentItem.tags = this.currentItem.tags.map(item => item.name)
      this.renameDialog = true
    },
    submitData() {
      this.tags = []
      this.$refs.formData.validate()
      if (this.valid) {
        this.submitEdit(this.currentItem)
        this.renameDialog = false
      }
    },
    getTags() {
      getTags({ search: this.search }).then(res => {
        this.tags = res.data.map(item => item.name)
      })
    },
  },
}
</script>

<style scoped></style>
