<template>
  <v-dialog v-model="dialog" persistent width="450">
    <v-form ref="formData" v-model="valid" onSubmit="return false;">
      <v-card class="custom-card-bg-gradient">
        <dialog-header :title="'Create new project'" @close="dialog = false" />
        <v-layout class="mb-8" justify-center>
          <div style="flex: none">
            <v-img :src="require('@/assets/svg/create.svg')" />
          </div>
        </v-layout>
        <v-card-text>
          <v-text-field
            v-model="name"
            :rules="[rules.nameRequired]"
            autofocus
            dense
            label="Name"
            outlined
            placeholder="Name"
          ></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-layout>
            <v-spacer />
            <v-btn :loading="loading" color="primary" rounded type="submit" @click="submitData">
              Submit
            </v-btn>
          </v-layout>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'
import { createProject } from '@/api/project-api'
import { mapState } from '@/store/ults'
export default {
  name: 'CreateDialog',
  components: { DialogHeader },
  data() {
    return {
      dialog: false,
      loading: false,
      valid: false,
      name: undefined,
      rules: {
        nameRequired: value => !!value || 'Name is required',
      },
    }
  },
  watch: {
    dialog(val) {
      this.name = undefined
      if (this.$refs.formData) {
        this.$refs.formData.resetValidation()
      }
    },
  },
  computed:{
    ...mapState('auth', ['currentUser']),
  },
  methods: {
    async submitData() {
      try {
        this.$refs.formData.validate()
        if (!this.valid) return
        this.loading = true
        const res =await createProject({ name: this.name })
        let projectMenu = this.currentUser.menus.filter(menu => menu.viewable)
        let AOIManagement = projectMenu.find(menu => menu.code == 'aoi-management')
        this.$router.push(`/projects/${res.data.uuid}/${AOIManagement ? AOIManagement.code : 'group-view?group=home'}`)
      } catch (e) {
      } finally {
        this.loading = false
        // this.$parent.getListProject()
        this.dialog = false
      }
    },
  },
}
</script>

<style scoped></style>
