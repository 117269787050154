<template>
  <v-layout column style="width: 100%; height: 100%; overflow-y: hidden">
    <v-container style="max-width: 1640px; height: 100%; overflow: hidden; position: relative">
      <v-layout align-center class="fill-height">
        <div style="height: 85vh; width: 100%; background: #2b2e4352; border-radius: 8px">
          <v-overlay :value="loading" absolute>
            <v-progress-circular indeterminate size="64"></v-progress-circular>
          </v-overlay>
          <v-layout class="fill-height overflow-y-hidden px-5" column>
            <div
              class="pb-7 d-flex align-center "
              style="flex: none; height: fit-content; width: 100%; text-align: center; border-bottom: 1px solid rgba(93, 101, 136, 0.5)"
            >
              <v-row align-center class="fill-height">
                <v-col cols="12" md="4" sm="12" xl="3">
                  <div class="d-flex align-center fill-height">
                    <h3 class="text-uppercase">Projects</h3>
                  </div>
                </v-col>
                <v-spacer />
                <v-col cols="12" md="4" sm="12" xl="3">
                  <div class="search-bar">
                    <div style="width: 100%">
                      <v-text-field
                        v-model="keyword"
                        append-icon="mdi-magnify"
                        background-color="#2F324180"
                        dense
                        hide-details
                        label="Search"
                        placeholder="Search"
                        rounded
                        solo
                        @input="searchProject"
                      />
                    </div>
                    <div class="clickable ml-2">
                      <v-menu min-width="160" offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon large v-bind="attrs" v-on="on">
                            <v-icon>mdi-sort</v-icon>
                          </v-btn>
                        </template>
                        <v-card>
                          <v-radio-group v-model="sort" @change="getListProject" hide-details class="mt-1">
                            <v-radio
                              color="secondary"
                              class="custom-checkbox"
                              label="Name (A-Z)"
                              value="name"
                            ></v-radio>
                            <v-radio
                              color="secondary"
                              class="custom-checkbox"
                              label="Name (Z-A)"
                              value="-name"
                            ></v-radio>
                            <v-radio
                              color="secondary"
                              class="custom-checkbox"
                              label="Favorite"
                              value="-starred,name"
                            ></v-radio>
                            <v-radio color="secondary" class="custom-checkbox" label="Newest" value="-created_at">
                            </v-radio>
                            <v-radio color="secondary" class="custom-checkbox" label="Oldest" value="created_at">
                            </v-radio> </v-radio-group
                        ></v-card>
                      </v-menu>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </div>
            <v-row class="overflow-y-auto mt-5">
              <v-col cols="12" md="12" sm="12">
                <v-row>
                  <v-col
                    v-for="(item, index) in projects"
                    :key="index"
                    cols="12"
                    md="3"
                    sm="12"
                    style="cursor: pointer"
                    xl="3"
                    class="item-container custom-col"
                  >
                    <div
                      class="pa-3 elevation-3 item-project"
                      :class="{ 'has-alert': false }"
                      @click="() => ($refs.createDialog.dialog = true)"
                      v-if="item.type == 'create'"
                      style="border-radius: 16px; position: relative; min-height: 150px;display:flex;flex-direction: column;justify-content:center"
                    >
                      <v-icon size="44">mdi-plus</v-icon>
                      <div style="width:100%;text-align: center;font-size:20px">
                        Create new project
                      </div>
                    </div>
                    <div
                      v-else
                      class="pa-3 elevation-3 item-project"
                      :class="{ 'has-alert': false }"
                      @click="selectProject(item)"
                      style="border-radius: 16px; position: relative; min-height: 150px;display:flex;flex-direction: column;"
                    >
                      <div class="tool">
                        <div style="min-height:21px">
                          <v-tooltip v-if="item.is_owner" top>
                            <template v-slot:activator="{ on, attrs }">
                              <div
                                class="clickable"
                                :class="{ starred: item.starred }"
                                @click.stop="toggleStar(item)"
                                style="cursor: pointer; filter: brightness(0.5);"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon class="icon" size="18">{{
                                  item.starred ? 'mdi-star' : 'mdi-star-outline'
                                }}</v-icon>
                              </div>
                            </template>
                            <span>{{ item.starred ? 'Remove from starred' : 'Add to starred' }}</span>
                          </v-tooltip>
                        </div>
                        <v-menu min-width="160" offset-y>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon large v-bind="attrs" v-on="on">
                              <v-icon style="font-size: 34px; color: white">icon-menu-dots </v-icon>
                            </v-btn>
                          </template>
                          <v-list dense>
                            <v-list-item v-for="(menu, index) in listMenusAvailable(item)" :key="index" :disabled="menu.disabled(item)" @click="menu.action(item)">
                              <v-list-item-icon>
                                <v-icon small>{{ menu.icon }}</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>
                                {{ menu.title }}
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                      <v-layout class="fill-height">
                        <div class="d-flex flex-column justify-space-between" style="width: 40px; height: 100%">
                          <v-img :src="require('@/assets/svg/package-location.svg')" width="32px" />
                          <IconNotification
                            :unreadNotificationsCount="item.is_owner ? item.unread_noti_count : 0"
                            :tooltip="getTooltipProject(item.is_owner ? item.unread_noti_count : 0)"
                            :disabled="true"
                            :styleIcon="{ marginTop: '6px', marginLeft: '-4px' }"
                          />
                        </div>
                        <v-layout class="fill-height pl-2" column>
                          <v-layout style="max-height: 24px" class="mb-2">
                            <div class="text-truncate" style="max-width: calc(100% - 50px); font-size: 1.1rem;">
                              {{ item.name }}
                            </div>
                          </v-layout>
                          <div class="caption">Owner: {{ item.owner ? item.owner.name : '' }}</div>
                          <div class="caption" style="line-height: 24px">
                            Total AOI:
                            {{ item.aois_count }}
                          </div>
                          <div class="caption" style="width: 100%">
                            <v-layout align-center class="fill-height"> Created at: {{ item.created_at }} </v-layout>
                          </div>

                          <div style="display: flex; flex-direction: row; justify-content: space-between;">
                            <div class="mt-1" style="display: flex;flex-direction: row;">
                              <div v-show="item.tags.length == 0 && item.is_owner">
                                <v-tooltip top>
                                  <template v-slot:activator="{ on, attrs }">
                                    <v-icon
                                      @click.stop="$refs.renameDialog.openDialog(item, true)"
                                      size="18"
                                      v-bind="attrs"
                                      v-on="on"
                                      >mdi-tag-plus-outline</v-icon
                                    >
                                  </template>
                                  <span>
                                    Add Tag
                                  </span>
                                </v-tooltip>
                              </div>

                              <v-chip
                                v-for="(tag, index) in item.tags.slice(0, 2)"
                                :key="index"
                                color="primary"
                                class="mr-1"
                                variant="flat"
                                small
                              >
                                {{ tag.name }}
                              </v-chip>

                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    @click.stop="$refs.renameDialog.openDialog(item, true)"
                                    v-show="item.tags.length > 2"
                                    class="mr-2"
                                    small
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                    style="order: 1;"
                                  >
                                    ...
                                  </v-chip>
                                </template>
                                <div style="background: #2F324180;max-width: 200px;">
                                  <v-chip
                                    v-for="(tag, index) in item.tags"
                                    :key="index"
                                    class="ma-1"
                                    variant="flat"
                                    color="primary"
                                    small
                                  >
                                    {{ tag.name }}
                                  </v-chip>
                                </div>
                              </v-tooltip>
                            </div>
                            <v-chip
                              style="align-self: flex-end;"
                              v-if="item.is_owner || item.permission"
                              class="mr-2 mt-2"
                              small
                            >
                              {{ item.is_owner ? 'Owner' : item.permission }}
                            </v-chip>
                          </div>
                        </v-layout>
                      </v-layout>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-layout>
        </div>
      </v-layout>
    </v-container>

    <CreateDialog ref="createDialog" />
    <ShareDialog ref="sharedialog" :permissions="permissions" />
    <DeleteDialog ref="deleteDialog" :submit-delete="deleteProject" />
    <RenameDialog ref="renameDialog" :submit-edit="editProject" />
    <menuConfig ref="menuConfig" :menus="currentUser.menus" />
  </v-layout>
</template>

<script>
import Message from '@/components/Message.vue'
import { deleteProject, getListProject, updateProject } from '@/api/project-api'
import { getSharePermission } from '@/api/user'
import CreateDialog from '@/views/project/CreateDialog.vue'
import DeleteDialog from '@/components/DeleteDialog.vue'
import ShareDialog from './ShareDialog.vue'
import RenameDialog from '@/components/RenameDialog.vue'
import ThemeSwitcher from '@/layouts/components/ThemeSwitcher.vue'
import AppBarUserMenu from '@/layouts/components/AppBarUserMenu.vue'
import { mapState } from '@/store/ults'
import { EDITOR } from '@/constants/permission'
import menuConfig from './menu-config.vue'
import IconNotification from '@/components/notification/icon-notification.vue'
import { debounce } from 'lodash'
import { checkGrammar } from '@/utils/stringHandle'

export default {
  name: 'Project',
  components: {
    AppBarUserMenu,
    ThemeSwitcher,
    RenameDialog,
    DeleteDialog,
    CreateDialog,
    Message,
    ShareDialog,
    menuConfig,
    IconNotification,
  },
  data() {
    return {
      keyword: undefined,
      loading: false,
      loadingProject: {},
      permissions: [],
      EDITOR,
      sort: '-starred,name',
      listMenus: [
        { title: 'Share', icon: 'mdi-share-variant-outline', visiable: () => true, disabled: () => false, action: (item) => this.$refs.sharedialog.openDialog(item) },
        { title: 'Edit', icon: 'icon-edit_square', visiable: () => true, disabled: (item) => item.permission !== EDITOR, action: (item) => this.$refs.renameDialog.openDialog(item) },
        { title: 'Config menu', icon: 'mdi-account-details', visiable: (item) => item.is_owner, disabled: () => false, action: (item) => this.$refs.menuConfig.openDialog(item) },
        { title: 'Delete', icon: 'icon-trash', visiable: () => true, disabled: (item) => item.permission !== EDITOR, action: (item) => this.$refs.deleteDialog.openDialog(item) },
      ],
      newItem: {
        type: 'create',
      },
    }
  },
  computed: {
    ...mapState('project', ['projectArea']),
    ...mapState('auth', ['currentUser']),
    ...mapState('layers', ['savedLayers']),
    ...mapState('menu', ['menu']),
    projects() {
      let projects = this.$store.getters['project/projects']
      projects.unshift(this.newItem)
      return projects
    },
  },
  mounted() {
    this.getListProject()
    this.getSharePermission()
  },
  methods: {
    listMenusAvailable(item) {
      return this.listMenus.filter(menu => menu.visiable(item))
    },
    getTooltipProject(num) {
      return checkGrammar(num, 'unread notification')
    },
    findDefaultMenu(menus, defaultMenuName, parents = []) {
      if (!Array.isArray(menus)) {
        return null
      }
      for (let i = 0; i < menus.length; i++) {
        if (menus[i].code === defaultMenuName) {
          return [...parents, menus[i].code]
        }

        if (Array.isArray(menus[i].children)) {
          let found = this.findDefaultMenu(menus[i].children, defaultMenuName, [...parents, menus[i].code])
          if (found) {
            return found
          }
        }
      }
      return null
    },
    selectProject(item) {
      this.projectArea = item.geometry
      this.savedLayers = []
      let default_menu = item.menus.find(menu => menu.default) || this.currentUser.menus.find(menu => menu.default)
      if (default_menu) {
        let group = this.findDefaultMenu(this.menu, default_menu.code).join('/')
        this.$router.push(`/projects/${item.uuid}/${default_menu.code}?group=${group}`)
      } else {
        let projectMenu = item.menus.filter(menu => menu.viewable)
        if (!this.currentUser.menu_grid) {
          this.$router.push(`/projects/${item.uuid}/${projectMenu[0].code}`)
          return
        }
        this.$router.push(`/projects/${item.uuid}/group-view?group=home`)
      }
    },
    async getSharePermission() {
      let data = await getSharePermission()
      this.permissions = data.data
    },
    async getListProject() {
      try {
        this.loading = true
        const params = { per_page: 'all', search: this.keyword, sort: this.sort }
        const projects = await this.$store.dispatch('project/getListProjects', params)
        projects.forEach(project => {
          this.loadingProject[project.id] = false
        })
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    searchProject: debounce(function() {
      this.getListProject()
    }, 800),
    async deleteProject(project) {
      try {
        await deleteProject(project.uuid)
        this.$refs.deleteDialog.deleteDialog = false
        this.loading = true
        await this.getListProject()
      } catch (e) {
        console.warn('Error when delete project: ', e)
      } finally {
        this.loading = false
      }
    },
    async editProject(project) {
      try {
        this.loading = true
        await updateProject({ id: project.uuid, data: { name: project.name, tags: project.tags } })
        await this.getListProject()
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    async toggleStar(project) {
      try {
        this.loadingProject[project.id] = true
        await updateProject({ id: project.uuid, data: { name: project.name, starred: !project.starred } })
        project.starred = !project.starred
      } catch (e) {
        Store.commit('message/SHOW_ERROR', e.response.data.message)
      } finally {
        this.loadingProject[project.id] = false
      }
    },
  },
}
</script>

<style scoped lang="scss">
.item-project {
  background-color: #2f324180;
  &.has-alert {
    //background: #b71c1c;
    animation: pulse 1s infinite;
  }
  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.5) !important;
    transform: scale3d(1.01, 1.01, 1.05);
  }
}
@media (min-width: 960px) and (max-width: 1098px) {
  .custom-col {
    width: 33.33% !important;
    flex: 0 0 33.33% !important;
    max-width: 33.33% !important;
  }
}
.tree-left {
  position: absolute;
  bottom: 0;
  left: 0;
}

.tree-right {
  position: absolute;
  bottom: -30px;
  right: -80px;
}

.starred {
  color: azure;
  filter: brightness(5.5) !important;
}

.icon:hover {
  color: azure;
}
.item-container{
  padding:0px 10px 20px 10px!important;
}

.tool {
  display: flex;
  position: absolute;
  right: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

::v-deep .theme--dark.v-icon {
  color: white !important;
}
.search-bar {
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}
</style>
