<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <dialog-header :title="titleDialog" @close="dialog = false" />
      <div class="pa-4 pt-0">
        <v-form v-if="permission === EDITOR" ref="formData" v-model="valid" onSubmit="return false;">
          <div class="d-flex align-center">
            <v-text-field
              v-model="mailInput"
              :hint="hintEmail"
              :rules="[rules.emailRules]"
              placeholder="Add email to set permission"
              prepend-inner-icon="icon-message"
              v-on:keyup.enter="handleAddEmail"
            ></v-text-field>
            <v-btn :disabled="disableAdd" color="pink" icon @click="handleAddEmail">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </div>
        </v-form>
        <div class="pr-3 pb-4" style="max-height: 250px; overflow-x: auto;">
          <div v-for="item in shareToEmails" :key="item.email" class="d-flex align-end">
            <div style="flex: 1; font-size: 13px;">
              {{ item.email }}
            </div>
            <div style="width: 130px">
              <v-select v-model="item.permission" :items="permissions" hide-details item-text="name" item-value="value">
                <template v-slot:append-item>
                  <v-divider class="mb-2"></v-divider>
                  <v-list-item @click="deletePremission">
                    <v-icon size="20">
                      icon-trash
                    </v-icon>
                    <v-list-item-title class="ml-2" @click="deleteShareEmail(item)">
                      Delete premission
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </div>
          </div>
        </div>
        <v-divider />
        <div class="mt-3" style="font-size: 13px;">
          People with permission
        </div>
        <div class="pr-3 pb-2" style="max-height: 300px; overflow-x: auto;">
          <div v-for="item in sharedEmails" :key="item.email" class="mt-0 d-flex justify-space-between align-center">
            <v-list-item class="pa-0">
              <v-list-item-avatar color="grey lighten-3">
                <v-avatar size="36" style="background: #3F51B5;">
                  <span class="white--text text-h7">{{ item.name[0] }}</span>
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-subtitle>
                  <span>
                    {{ item.name }}
                  </span>
                  <i v-if="item.organization" class="ml-2" style="font-size: 13px; color: #979A9A"
                    >({{ item.organization }})</i
                  >
                </v-list-item-subtitle>
                <v-list-item-title>
                  {{ item.email }}
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <div style="width: 130px">
              <v-select
                v-model="item.permission"
                :disabled="permission !== EDITOR"
                :items="permissions"
                hide-details
                item-text="name"
                item-value="value"
                @change="changeShareEmail(item)"
              >
                <template v-slot:append-item>
                  <v-divider class="mb-2"></v-divider>
                  <v-list-item @click="deletePremission(item)">
                    <v-icon size="20">
                      icon-trash
                    </v-icon>
                    <v-list-item-title class="ml-2">
                      Delete premission
                    </v-list-item-title>
                  </v-list-item>
                </template>
              </v-select>
            </div>
          </div>
        </div>
      </div>
      <v-card-actions v-if="permission === EDITOR">
        <v-layout class="pt-2">
          <v-spacer />
          <v-btn :loading="loading" color="primary" rounded @click="submitData">
            Done
          </v-btn>
        </v-layout>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DialogHeader from '@/components/DialogHeader.vue'
import { addShare, deleteShared, getShared, updateShared } from '@/api/project-api'
import { mapState } from '@/store/ults'
import { EDITOR, VIEWER } from '@/constants/permission'

export default {
  name: 'CreateDialog',
  components: { DialogHeader },
  props: {
    permissions: { type: Array, default: () => [] },
  },
  computed: {
    ...mapState('auth', ['currentUser']),
  },
  data() {
    return {
      dialog: false,
      loading: false,
      valid: false,
      mailInput: '',
      rules: {
        nameRequired: value => !!value || 'Name is required',
        emailRules: v => /.+@.+/.test(v) || 'E-mail must be valid',
      },
      mail: [],
      shareToEmails: [],
      sharedEmails: [],
      titleDialog: '',
      disableAdd: true,
      hintEmail: 'Press Enter to add email to list share',
      project: {},
      permission: null,
      EDITOR,
    }
  },
  watch: {
    mailInput(value) {
      this.disableAdd = !this.$refs.formData.validate()
    },
  },
  methods: {
    async openDialog(project) {
      this.dialog = true
      this.titleDialog = `Set people permission for ${project.name}`
      this.project = project
      this.permission = project.permission
      this.mailInput = ''
      this.shareToEmails = []
      await this.getShared()
    },
    async getShared() {
      let data = await getShared(this.project.uuid)
      this.sharedEmails = data.data
    },
    handleAddEmail() {
      if (this.$refs.formData.validate()) {
        const checkAdd = this.shareToEmails.find(el => el.email.toLowerCase() === this.mailInput.toLowerCase())
        const checkShared = this.sharedEmails.find(el => el.email.toLowerCase() === this.mailInput.toLowerCase())
        if (checkAdd) {
          this.hintEmail = 'This Email already exists for add'
          return
        }
        if (checkShared) {
          this.hintEmail = 'This Email already exists for shared'
          return
        }
        this.shareToEmails.push({ email: this.mailInput, permission: VIEWER })
        this.mailInput = ''
        this.$refs.formData.resetValidation()
        this.hintEmail = 'Press Enter to add email to list share'
      }
    },
    async changeShareEmail(e, item) {
      if (item)
        await updateShared({
          project_id: this.project.uuid,
          id: item.uuid,
          permission: item.permission,
        })
    },
    deleteShareEmail(item) {
      this.shareToEmails = this.shareToEmails.filter(el => item.email !== el.email)
    },
    async deletePremission(item) {
      if (item)
        await deleteShared({
          project_id: this.project.uuid,
          id: item.uuid,
        })
      this.getShared()
    },
    async submitData() {
      try {
        this.loading = true
        this.$refs.formData.resetValidation()
        if (this.shareToEmails && this.shareToEmails.length > 0)
          await addShare({
            project_id: this.project.uuid,
            users: this.shareToEmails,
          })
        this.dialog = false
      } catch (e) {
      } finally {
        this.loading = false
        this.$parent.getListProject()
        // this.dialog = false
      }
    },
  },
}
</script>

<style scoped></style>
